import { Radio, Collapse, Input, Button } from 'antd';
import { months } from 'src/utils/calendar-info';
import { useSearchParams } from 'react-router-dom';
import { useMutation, useQueryClient, useQuery } from '@tanstack/react-query';
import { Balancer } from 'react-wrap-balancer';
import api from 'src/api';
import d from 'dayjs';
import Spinner from './spinner';
import { useCalendarEntries } from 'src/hooks/calendar-entries.queries';
import { FaPhone } from 'react-icons/fa6';
import { MdEmail } from 'react-icons/md';
import { useEffect, useState } from 'react';

const iconStyles = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '30px',
  width: '30px',
  borderRadius: '100vw',
  backgroundColor: '#f1f1f1'
};

function ManageApartmentDayCard({
  selectedDate,
  month,
  year,
  setUpdatingCalendar
}) {
  const [searchParams] = useSearchParams();
  const propertyId = searchParams.get('propertyId');
  const queryClient = useQueryClient();
  const from = d()
    .set('date', selectedDate.date)
    .set('month', month)
    .set('year', year);

  const { isFetching, calendarEntries } = useCalendarEntries({
    from,
    propertyId
  });

  const [entry, setEntry] = useState({ isBlocked: false });
  const [text, setText] = useState('');

  const entryExists = !!entry?._id;

  const { mutate, status } = useMutation({
    mutationFn: async () => {
      setUpdatingCalendar(true);
      const isBlocked = entry.isBlocked;

      const promises = selectedDate.map(async date => {
        const formattedDate = d()
          .set('date', date)
          .set('month', month)
          .set('year', year)
          .format('YYYY-MM-DD');

        try {
          if (entryExists && !isBlocked) {
            return await api.delete(`/calendar/${entry._id}`);
          } else {
            return await api.post('/calendar', {
              propertyId,
              date: formattedDate,
              isBlocked,
              ...(text && { reason: text })
            });
          }
        } catch (error) {
          console.error(`Error updating date ${formattedDate}:`, error);
          throw error;
        }
      });

      await Promise.all(promises);

      // Invalidate all selected date entries
      selectedDate.forEach(date => {
        const dateKey = d()
          .set('date', date)
          .set('month', month)
          .set('year', year)
          .format('YYYY-MM-DD');

        queryClient.invalidateQueries(
          ['calendar-entries', propertyId, dateKey],
          {
            exact: true
          }
        );
      });
    },
    onSettled: () => {
      setUpdatingCalendar(false);
    }
  });

  const {
    data: booking,
    error: bookingError,
    isLoading: isBookingInfoLoading
  } = useQuery({
    queryKey: ['booking', entry?.bookingId, selectedDate.date],
    enabled: !!entry?.bookingId,
    staleTime: 0,
    queryFn: async () => {
      const { data: { booking = {} } = {} } = await api.get(
        `/booking/one/${entry?.bookingId}`
      );
      return booking;
    }
  });

  const {
    data: user,
    error: userError,
    isLoading: isUserLoading
  } = useQuery({
    queryKey: ['user-details', booking?.userId, selectedDate.date],
    enabled: !!booking?.userId,
    queryFn: async () => {
      const { data: { user = {} } = {} } = await api.get(
        `/user/basic-details/${booking?.userId}${
          booking?.guestList?.length
            ? `/${booking?.guestList?.join?.(' ')}`
            : ''
        }`
      );
      return user;
    }
  });
  const pkgDetails = booking?.pkgDetails
    ? JSON.parse(booking?.pkgDetails)
    : null;
  const guestsInfo = user?.guests?.map(guest => ({
    key: guest?._id,
    label: guest?.firstName + ' ' + guest?.lastName,
    children: (
      <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <div style={iconStyles}>
            <FaPhone />
          </div>
          <p>{guest?.phone}</p>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <div style={iconStyles}>
            <MdEmail />
          </div>
          <p>{guest?.email}</p>
        </div>
      </div>
    )
  }));

  useEffect(() => {
    if (calendarEntries?.[0]) {
      setEntry(calendarEntries[0]);
      setText(calendarEntries[0].reason);
    } else {
      setEntry({ isBlocked: false });
      setText('');
    }
  }, [selectedDate, calendarEntries?.[0]]);

  const diff = d(booking?.checkOutDate).diff(d(booking?.checkInDate), 'day');
  const displaySelectedDates = () => {
    if (selectedDate.length === 0) return '';

    const minDay = Math.min(...selectedDate);
    const maxDay = Math.max(...selectedDate);

    if (minDay === maxDay) {
      return `${minDay} ${months[month]} ${year}`;
    } else {
      return `${minDay} - ${maxDay} ${months[month]} ${year}`;
    }
  };
  return (
    <div
      style={{
        minHeight: '500px',
        width: '100%',
        border: '1px solid #8d9197',
        background: '#fff',
        borderRadius: '10px',
        padding: '10px',
        display: 'flex',
        flexDirection: 'column',
        gap: '10px'
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          background: '#f1f1f173',
          padding: '10px',
          borderRadius: '10px'
        }}
      >
        <p style={{ fontWeight: '500' }}>{displaySelectedDates()}</p>
        {entry?.bookingId && (
          <p
            style={{
              color: '#2a9d8f',
              background: '#e9f7f6',
              width: 'fit-content',
              padding: '5px 20px',
              borderRadius: '100vw',
              fontWeight: 600
            }}
          >
            Booked
          </p>
        )}
        {entry?.isBlocked && (
          <p
            style={{
              color: '#f02929',
              background: '#f7e9e9',
              width: 'fit-content',
              padding: '5px 20px',
              borderRadius: '100vw',
              fontWeight: 600
            }}
          >
            Blocked
          </p>
        )}
      </div>
      {!entry?.bookingId && (
        <h4>
          <Balancer>Open and Close for Bookings</Balancer>
        </h4>
      )}

      <div>
        {status === 'pending' || isFetching ? (
          <Spinner />
        ) : (
          !entry?.bookingId && (
            <Radio.Group
              defaultValue={entry?.isBlocked ? 'blocked' : 'open'}
              onChange={e => {
                const isBlocked = e.target.value === 'blocked';
                setEntry(prev => ({ ...prev, isBlocked }));
              }}
            >
              <Radio value={'open'}>Open</Radio>
              <Radio value={'blocked'}>Close</Radio>
            </Radio.Group>
          )
        )}
      </div>
      {isBookingInfoLoading ? (
        <Spinner />
      ) : bookingError ? (
        <p style={{ color: 'red' }}>Error fetching booking details</p>
      ) : (
        entry?.bookingId && (
          <div>
            <h4 style={{ color: 'var(--main-brand-color)' }}>
              Booking Details
            </h4>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                marginTop: '5px'
              }}
            >
              <h5>Check In</h5>
              <h5>Check Out</h5>
              <p>{d(booking?.checkInDate).format('DD MMM YYYY')}</p>
              <p>{d(booking?.checkOutDate).format('DD MMM YYYY')}</p>
            </div>
            {pkgDetails && (
              <>
                <h5 style={{ marginTop: '5px' }}>Details</h5>
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr'
                  }}
                >
                  <h6>No. of Adults</h6>
                  <h6>Paid Amount</h6>
                  <p>{pkgDetails.noOfAdults}</p>
                  <p>{pkgDetails.discountedAmount}</p>
                  <h6>Status</h6>
                  <h6>Length Of Stay</h6>
                  <p style={{ color: '#2a9d95' }}>{booking.status}</p>
                  <p>
                    {diff} {diff > 1 ? 'days' : 'day'}
                  </p>
                </div>
              </>
            )}
          </div>
        )
      )}
      {userError ? (
        <p>Error fetching user details</p>
      ) : isUserLoading ? (
        <Spinner />
      ) : (
        booking?.userId && (
          <div style={{ display: 'flex', gap: '5px', flexDirection: 'column' }}>
            <h4 style={{ color: 'var(--main-brand-color)' }}>User Details</h4>
            <h5>Booked By</h5>
            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              <div
                style={{
                  ...iconStyles,
                  backgroundImage: `url("${process.env.REACT_APP_SERVER_URL}/images/${user?.profilePic}")`
                }}
              >
                {!user?.profilePic && (
                  <h4>
                    {user?.fName?.charAt?.(0)}
                    {user?.lName?.charAt?.(0)}
                  </h4>
                )}
              </div>
              <h5 style={{ textTransform: 'capitalize' }}>
                <Balancer>
                  {user?.fName} {user?.lName}
                </Balancer>
              </h5>
            </div>
            {user?.phone && (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px'
                }}
              >
                <div style={iconStyles}>
                  <FaPhone />
                </div>
                <p>
                  {user?.countryCode} {user?.phone}
                </p>
              </div>
            )}
            {user?.email && (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px'
                  // marginLeft: '10px'
                }}
              >
                <div style={iconStyles}>
                  <MdEmail size={20} />
                </div>
                <p
                  style={{
                    wordBreak: 'break-word'
                  }}
                >
                  <Balancer>{user?.email}</Balancer>
                </p>
              </div>
            )}
          </div>
        )
      )}
      {!!guestsInfo?.length && (
        <div>
          <h4
            style={{ marginBottom: '10px', color: 'var(--main-brand-color)' }}
          >
            Guests Info
          </h4>
          <Collapse
            items={guestsInfo}
            defaultActiveKey={[guestsInfo?.[0]?.key]}
          />
        </div>
      )}
      {entry.isBlocked && (
        <Input.TextArea
          rows={4}
          value={text}
          onChange={e => setText(e.target.value)}
        />
      )}
      <Button type="primary" onClick={mutate}>
        Save
      </Button>
    </div>
  );
}

export default ManageApartmentDayCard;
