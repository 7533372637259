import { GoCheckCircleFill } from 'react-icons/go';
import { HiLockClosed } from 'react-icons/hi';
import { useSearchParams } from 'react-router-dom';
import { useCalendarEntries } from 'src/hooks/calendar-entries.queries';
import Spinner from './spinner';
import d from 'dayjs';
import { FaCalendar } from 'react-icons/fa6';
import { useQuery } from '@tanstack/react-query';
import api from 'src/api';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';

function DayCard({
  selectedDate,
  setSelectedDate,
  from,
  date,
  month,
  year,
  roomId,
  room,
  handleMouseDown,
  handleMouseEnter
}) {
  const [searchParams] = useSearchParams();
  const propertyId = searchParams.get('propertyId');
  const isPast =
    d() > d().set('date', date).set('month', month).set('year', year);
  const [status, setStatus] = useState('');
  const [day, setDay] = useState({});

  const { data: property } = useQuery({
    queryKey: ['property', propertyId, ['propertyName', 'propertyType']],
    queryFn: async ({ queryKey }) => {
      const { data: { property = {} } = {} } = await api.get(
        `/properties/find/${propertyId}?select=${queryKey?.[2]?.join?.(' ')}`
      );
      return property;
    }
  });

  const { error, isFetching, calendarEntries } = useCalendarEntries({
    from: date ? from.set('date', date) : from,
    propertyId,
    roomId
  });

  // const { data: rooms } = useQuery({
  //   queryKey: ['distinct-rooms', propertyId],
  //   enabled: property?.propertyType === 'hotel',
  //   queryFn: async () => {
  //     const { data: { rooms = [] } = {} } = await api.get(
  //       `/hotel-rooms/${propertyId}/distinct?select=roomName`
  //     );
  //     const hotelRooms = {};
  //     rooms.forEach(room => {
  //       hotelRooms[room.roomTypeId] = room;
  //     });
  //     return hotelRooms;
  //   }
  // });

  useEffect(() => {
    if (property) {
      let newDay = {};
      let newStatus = '';
  
      if (property.propertyType === 'hotel' && room) {
        if (calendarEntries.length) {
          newStatus =
            calendarEntries[0].roomCount === room.count
              ? 'blocked'
              : `${room.count - calendarEntries[0].roomCount} left to sell`;
        } else {
          newStatus = `${room.count} left to sell`;
        }
      } else {
        newDay = calendarEntries?.[0] ?? {};
        newStatus = newDay?.isBlocked ? 'blocked' : 'Available';
      }
  
      // Update only if values have changed to avoid re-render loop
      if (status !== newStatus) setStatus(newStatus);
      if (JSON.stringify(day) !== JSON.stringify(newDay)) setDay(newDay);
    }
  }, [property, calendarEntries, room, status, day]);
  

  return (
    <div
      onMouseDown={() => {
        if (
          dayjs(`${year}-${month + 1}-${date}`)
            .startOf('day')
            .isBefore(dayjs().startOf('day'))
        ) {
          return;
        }
        setSelectedDate([]);
        handleMouseDown(date);
      }}
      onMouseEnter={() => {
        handleMouseEnter(date);
      }}
      style={{ height: '140px', aspectRatio: '0/0', userSelect: 'none' }}
      className={` __calender_day 
   --${status} 
   ${Object.values(selectedDate)?.includes(date) && '--selected'}
   ${isPast && '--past'} selectable
   `}
      key={'day-' + date}
    >
      {error ? (
        'error'
      ) : isFetching ? (
        <Spinner />
      ) : (
        <>
          <p style={{ fontWeight: 600, color: 'black' }}>{date}</p>
          <p style={{ textAlign: 'center' }}>
            {status === 'blocked' ? (
              <HiLockClosed size={24} />
            ) : status === 'booked' ? (
              <GoCheckCircleFill size={24} />
            ) : (
              <FaCalendar color="#c3c3c3" size={24} />
            )}
          </p>
          {!isPast && (
            <p
              style={{
                textAlign: 'center',
                textTransform: 'capitalize',
                alignSelf: 'center',
                fontWeight: 500
              }}
            >
              {status}
            </p>
          )}
        </>
      )}
    </div>
  );
}

export default DayCard;
